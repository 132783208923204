import CircleLoader from "react-spinners/ClipLoader";
import './loader.scss'

const Loader = ({ className }) => {
    return (
        <section id="loader">
            <div className={`loader ${className ? className : ''}`}>
                <CircleLoader color="#fff" size={100} />
            </div>

        </section>
    )
}

export default Loader