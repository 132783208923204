import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./sidebar.scss";
import CommanService from "../../CommanService/commanService";
import { addJewelleryProduct, editDiamondAction, getLoginDetailsData, handleActivePoductTab, handleAddDynamicParamsAction, handleCompleteProduct, handleSetActiveTabName, handleSingleDiamondAction, isDiamoDIY, isJewelDIY, selectDiamondAction, setStepperTab, storeSectionUrl, storeSubNavbarName } from "../../Redux/action"
import { diamoSessionData, jewelSessionData, looseSessionData, isEmpty, jewelVertical } from "../../Components/CommanFunctions/commanFunctions";

const SideBar = (props) => {

    const selector = useSelector(state => state)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [seconedSidebar, setSeconedSidebar] = useState(false);
    const [menuDataList, setMenuDataList] = useState([]);

    const signOut = () => {
        dispatch(getLoginDetailsData({}))
        dispatch(storeSectionUrl(""))
        dispatch(storeSubNavbarName(""))
        navigate("/")
    }
    // mobile menu toggleSidebar
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const commanFunctions = () => {
        sessionStorage.removeItem("collection")
        jewelSessionData()
        diamoSessionData()
        looseSessionData()
    }

    const menuData = () => {
        var obj = {
            a: "GetNavigationMaster",
            number: "0",
            per_page: "0",
            SITDeveloper: '1',
            sorting_order: '',
            sorting_column: '',
            flag: 'false',
            type: 'POS',
            store_id: selector.storeEntityId.mini_program_id,
        }
        if (sessionStorage.getItem('menuData') == undefined) {
            CommanService.postLaravelApi2("NavigationMegamenu", obj).then((res) => {
                setMenuDataList(res.data.data)
                sessionStorage.setItem('menuData', JSON.stringify(res.data.data))
            })
        } else {
            setMenuDataList(JSON.parse(sessionStorage.getItem('menuData')))
        }

    }

    useEffect(() => {
        // Campaign open to sidebar Remove & logout
        const sidebar = document.getElementById("MainSideBar1");
        if (sidebar && props.fixsidebar === "") {
            sidebar.remove();
        }

        menuData();
        if (props.fixsidebar == false) {
            document.body.classList.remove("OpenSidebar")
        } else {
            document.body.classList.add("OpenSidebar")
        }
    }, [props.fixsidebar]);

    const handleInitilize = () => {
        dispatch(setStepperTab(""));
        dispatch(handleSingleDiamondAction({}));
        dispatch(handleAddDynamicParamsAction({}));
        dispatch(editDiamondAction(""));
        dispatch(addJewelleryProduct({}));
        dispatch(handleCompleteProduct(false));
        dispatch(selectDiamondAction({}));
    };

    useEffect(() => {
        if (!window.location.pathname.includes("start-with-a-diamond")) {
            handleInitilize();
        }
        if (window.location.pathname === '/jewellery/start-with-a-setting') {
            dispatch(handleActivePoductTab("SETTING"));
        }
    }, [window.location.pathname]);

    return (
        <div id="SideBar">
            <div id="MainSideBar1" className={props.fixsidebar === false ? "" : "MainSideBar"}>
                <div id="MainSideBar" >
                    <div className="logoDashboard">
                        <div className="Navbar">
                            <div className="text">POS</div>
                        </div>
                        <div className="main-profile">
                            {isEmpty(selector.data.profile_image) !== "" ?
                                <img alt="Profile" src={selector.data.profile_image} className="symbol" />
                                : <div className="profile-icon"><i className="ic_user"></i></div>
                            }
                            <div className="user-details">
                                <div className="user-name">{selector.data.fname} {selector.data.lname}</div>
                                <div className="user-email">{selector.data.email}</div>
                            </div>
                        </div>
                        <div className="profile-line"></div>
                    </div>
                </div>
                <div className="sidebarscroll">
                    <Link to={"/dashborad"} className={`SidebarMenu ${selector.storeSectionUrl === "dashboard" && "active"}`} onClick={(event) => { if (window.location.pathname === "/dashborad") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/dashborad"); dispatch(isJewelDIY(false)); dispatch(isDiamoDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("dashboard")); setSeconedSidebar(false); props.fixsidebarclickclose(); }} >
                        <i className="ic_home"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Dashboard</span>
                    </Link>
                    {menuDataList.length > 0 ?
                        <>
                            {menuDataList.map((m, index) =>
                                <React.Fragment key={index}>
                                    {(m.product_vertical_name !== "LGDIA" && m.product_vertical_name !== 'DIY' && m.product_vertical_name !== 'DIAMO' && m.product_vertical_name !== 'GEDIA' && isEmpty(m.status) == '1') ?
                                        <Link to={`/products/${m.product_vertical_name}`} onClick={(event) => {
                                            if (window.location.pathname === `/products/${m.product_vertical_name}`) { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", `/products/${m.product_vertical_name}`); commanFunctions(); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl(m.product_vertical_name)); setSeconedSidebar(false); props.fixsidebarclickclose();
                                            dispatch(handleSetActiveTabName(m.menu_name));
                                        }} className={`SidebarMenu ${selector.storeSectionUrl === m.product_vertical_name && "active"}`}>
                                            <i className={m.product_vertical_name === 'JEWEL' ? "ic_jewellery"
                                                : m.product_vertical_name === 'FRAME' ? "ic_frame"
                                                    : m.product_vertical_name === 'WATCH' ? "ic_watch"
                                                        : m.product_vertical_name === 'FOOTW' ? "ic_footwear"
                                                            : "ic_product_management"}></i>
                                            <span className={`${props.fixsidebar !== false && "active"}`}>{m.menu_name}</span>
                                        </Link>
                                        : ""
                                    }
                                    {m.product_vertical_name == 'DIY' && isEmpty(m.status) == '1' ?
                                        <Link to={"/jewellery/start-with-a-setting"} onClick={(event) => { if (window.location.pathname === "/jewellery/start-with-a-setting") { event.stopPropagation(); dispatch(storeSectionUrl("JEWELDIY")); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/jewellery/start-with-a-setting"); commanFunctions(); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("JEWEL")); setSeconedSidebar(false); props.fixsidebarclickclose(); dispatch(handleSetActiveTabName(m.menu_name)); }} className={`SidebarMenu ${selector.storeSectionUrl === "JEWELDIY" && "active"} `}>
                                            <i className="ic_certi_diamond_jewelry"></i>
                                            <span className={`${props.fixsidebar !== false && "active"}`}>{m.menu_name}</span>
                                        </Link>
                                        : ''}
                                    {m.product_vertical_name == 'DIAMO' && isEmpty(m.status) == '1' ?
                                        <Link to={"/certificate-diamond"} onClick={(event) => {
                                            if (window.location.pathname === "/certificate-diamond") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/certificate-diamond"); commanFunctions(); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("DIAMO")); setSeconedSidebar(false); props.fixsidebarclickclose();
                                            dispatch(handleSetActiveTabName(m.menu_name));
                                        }}
                                            className={`SidebarMenu ${selector.storeSectionUrl === "DIAMO" && "active"}`}>
                                            <i className="ic_certificate_diamond"></i>
                                            <span className={`${props.fixsidebar !== false && "active"}`}>{m.menu_name}</span>
                                        </Link>
                                        : ''}
                                    {m.product_vertical_name == 'LGDIA' && isEmpty(m.status) == '1' ?
                                        <Link to={"/lab-grown-diamond"} onClick={(event) => {
                                            if (window.location.pathname === "/lab-grown-diamond") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/lab-grown-diamond"); commanFunctions(); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("LGDIA")); setSeconedSidebar(false); props.fixsidebarclickclose();
                                            dispatch(handleSetActiveTabName(m.menu_name));
                                        }}
                                            className={`SidebarMenu ${selector.storeSectionUrl === "LGDIA" && "active"}`}>
                                            <i className="ic_lab_diamond"></i>
                                            <span className={`${props.fixsidebar !== false && "active"}`}>{m.menu_name}</span>
                                        </Link>
                                        : ''}
                                    {m.product_vertical_name == 'GEDIA' && isEmpty(m.status) == '1' ?
                                        <Link to={"/lab-grown-gemstone"} onClick={(event) => {
                                            if (window.location.pathname === "/lab-grown-gemstone") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/lab-grown-gemstone"); commanFunctions(); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("GEDIA")); setSeconedSidebar(false); props.fixsidebarclickclose();
                                            dispatch(handleSetActiveTabName(m.menu_name));
                                        }}
                                            className={`SidebarMenu ${selector.storeSectionUrl === "GEDIA" && "active"}`}>
                                            <i className="ic_certificate_diamond"></i>
                                            <span className={`${props.fixsidebar !== false && "active"}`}>{m.menu_name}</span>
                                        </Link>
                                        : ''}
                                </React.Fragment>
                            )}
                        </>
                        : ''}

                    <Link onClick={(event) => { if (window.location.pathname === "/order-management") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/order-management"); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("OrderManagement")); setSeconedSidebar(false); props.fixsidebarclickclose(); }} to={"/order-management"} className={`SidebarMenu ${selector.storeSectionUrl === "OrderManagement" ? "active" : ""}`}>
                        <i className="ic_order_management"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Order&#160;Management</span>
                    </Link>

                    <Link onClick={(event) => { if (window.location.pathname === "/inventory") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/inventory"); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("Inventory")); setSeconedSidebar(false); props.fixsidebarclickclose(); }} to={"/inventory"} className={`SidebarMenu ${selector.storeSectionUrl === "Inventory" ? "active" : ""}`}>
                        <i className="ic_inventory"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Inventory</span>
                    </Link>

                    <Link onClick={(event) => { if (window.location.pathname === "/shipment") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/shipment"); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("Shipment")); setSeconedSidebar(false); props.fixsidebarclickclose(); }} to={"/shipment"} className={`SidebarMenu ${selector.storeSectionUrl === "Shipment" ? "active" : ""}`}>
                        <i className="ic_shipment"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Shipment</span>
                    </Link>

                    <Link onClick={(event) => { if (window.location.pathname === "/sales-invoice") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/sales-invoice"); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("SalesInvoice")); setSeconedSidebar(false); props.fixsidebarclickclose(); }} to={"/sales-invoice"} className={`SidebarMenu ${selector.storeSectionUrl === "SalesInvoice" ? "active" : ""}`}>
                        <i className="ic_inventory_file"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Sales Invoice</span>
                    </Link>

                    <Link onClick={(event) => { if (window.location.pathname === "/consumerlist ") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/consumerlist"); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("Consumer List")); setSeconedSidebar(false); props.fixsidebarclickclose(); }} to={"/consumerlist"} className={`SidebarMenu ${selector.storeSectionUrl === "Consumer List" ? "active" : ""}`}>
                        <i className="ic_user_preference"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Consumers</span>
                    </Link>

                    <Link onClick={(event) => { if (window.location.pathname === "/my-team") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/my-team"); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("My Team")); setSeconedSidebar(false); props.fixsidebarclickclose(); }} to={"/my-team"} className={`SidebarMenu ${selector.storeSectionUrl === "My Team" ? "active" : ""}`}>
                        <i className="ic_user_group"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>My&#160;Team</span>
                    </Link>

                    <Link onClick={(event) => { if (window.location.pathname === "/coupon-code") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/coupon-code"); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("Coupon Code")); setSeconedSidebar(false); props.fixsidebarclickclose(); }} to={"/coupon-code"} className={`SidebarMenu ${selector.storeSectionUrl === "Coupon Code" ? "active" : ""}`}>
                        <i className="ic_ticket"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Coupon&#160;Code</span>
                    </Link>
                    <Link onClick={(event) => { if (window.location.pathname === "/warranty-card") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/warranty-card"); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("Warranty Card")); setSeconedSidebar(false); props.fixsidebarclickclose(); }} to={"/warranty-card"} className={`SidebarMenu ${selector.storeSectionUrl === "Warranty Card" ? "active" : ""}`}>
                        <i className="ic_warranty_card"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Warranty&#160;Card</span>
                    </Link>
                    <Link onClick={(event) => { if (window.location.pathname === "/inquiry") { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", "/inquiry"); dispatch(isDiamoDIY(false)); dispatch(isJewelDIY(false)); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl("Inquiry")); setSeconedSidebar(false); props.fixsidebarclickclose(); }} to={"/inquiry"} className={`SidebarMenu ${selector.storeSectionUrl === "Inquiry" ? "active" : ""}`}>
                        <i className="ic_inquiry"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Inquiry</span>
                    </Link>
                    <Link to={"/start-with-a-diamond"} onClick={(event) => {
                        if (window.location.pathname === "/start-with-a-diamond") {
                            event.stopPropagation();
                            event.preventDefault();
                            navigate("/-")
                        }
                        sessionStorage.setItem("storeUrl", "/start-with-a-diamond");
                        commanFunctions();
                        handleInitilize();
                        dispatch(isJewelDIY(false));
                        dispatch(storeSubNavbarName(""));
                        dispatch(storeSectionUrl("PRODUCT_DIY"));
                        props.fixsidebarclickclose();
                    }}
                        className={`SidebarMenu ${selector.storeSectionUrl === "PRODUCT_DIY" && "active"}`}>
                        <i className="ic_certi_diamond_jewelry"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Start With Diamond</span>
                    </Link>

                </div>
                <div className="logout-menu">
                    <div onClick={() => signOut()} className="SidebarMenu">
                        <i className="ic_logout"></i>
                        <span className={`${props.fixsidebar !== false && "active"}`}>Sign&#160;Out</span>
                    </div>
                </div>
            </div>
            {props.fixsidebar !== false && <div className="SiderbarMain" onClick={() => { setSeconedSidebar(false); props.fixsidebarclickclose(); }}></div>}
        </div>
    );
}

export default SideBar;