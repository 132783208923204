import axios from "axios";
export var imageUrl;
export var domain;
var baseUrl1 = '';
var baseUrlCI = '';
var baseUrl3 = '';

if ((window.location.origin).includes('localhost')) {
    imageUrl = "http://192.168.84.66:8080";
    baseUrl1 = "http://192.168.84.66:8080/api/pos";
    baseUrlCI = "http://192.168.84.66/sit-ci-api/call";
    baseUrl3 = "http://192.168.84.66:8080/api/call";
    domain = 'https://uat-pos-str5100014.zurahjewellery.com';
    // domain = 'https://uat-pos.diamondsforapurpose.com';
    // domain = 'https://uat-pos.zurahjewellery.com';
    /* imageUrl = "https://apistaging-ecom-store.upqor.com"
    baseUrl1 = "https://apistaging-ecom-store.upqor.com/api/pos";
    baseUrlCI = "https://apistaging-ecom.upqor.com/call";
    baseUrl3 = "https://apistaging-ecom-store.upqor.com/api/call"
    domain = 'https://staging-pos-str5100013.zurahjewellery.com'; */
}
else if ((window.location.origin).includes('staging')) {
    imageUrl = "https://apistaging-ecom-store.upqor.com"
    baseUrl1 = "https://apistaging-ecom-store.upqor.com/api/pos";
    baseUrlCI = "https://apistaging-ecom.upqor.com/call";
    baseUrl3 = "https://apistaging-ecom-store.upqor.com/api/call"
    domain = window.location.origin;
}
else if ((window.location.origin).includes('uat')) {
    imageUrl = "https://apiuat-ecom-store.upqor.com"
    baseUrl1 = "https://apiuat-ecom-store.upqor.com/api/pos";
    baseUrlCI = "https://apiuat-ecom.upqor.com/call";
    baseUrl3 = "https://apiuat-ecom-store.upqor.com/api/call";
    domain = window.location.origin;
}
else {
    imageUrl = "https://api-ecom-store.upqor.com"
    baseUrl1 = "https://api-ecom-store.upqor.com/api/pos";
    baseUrlCI = "https://api-ecom.upqor.com/call";
    baseUrl3 = "https://api-ecom-store.upqor.com/api/call"
    domain = window.location.origin;
}
class Commanservice {

    postLaravelApi(controller, obj) {
        return axios.post(baseUrl1 + "/" + controller, obj);
    }

    postLaravelApi2(controller, obj) {
        return axios.post(baseUrl3 + "/" + controller, obj);
    }

    postApi(controller, obj, signal) {
        if (signal === undefined) {
            return axios.post(baseUrlCI + "/" + controller, obj);
        } else {
            return axios.post(baseUrlCI + "/" + controller, obj, {
                signal: signal
            });
        }
    }

    obj_json(obj, value) {
        const data = [];
        data["a"] = value;
        for (const pair of obj) {
            data[pair[0]] = pair[1];
        }
        const arrayToString = JSON.stringify(Object.assign({}, data));
        return arrayToString;
    }
}

export default new Commanservice();
